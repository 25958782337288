<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ sprintf($t('pages.ecommerce.invoiceManagement.provider.product.titlePattern'), [provider.name ?? '']) }}
        </h2>

        <router-link to="/ecommerce/invoice-management/provider" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.ecommerce.invoiceManagement.provider.title") }}
        </router-link>
    </div>
    <custom-table
        :title="$t('pages.ecommerce.invoiceManagement.provider.product.title')"
        :subTitle="$t('pages.ecommerce.invoiceManagement.provider.product.subTitle')"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        changeUrlPage
        filterComponentName="EcommerceInvoiceProviderProductFilter"
        @action="handleClickAction"
        @changeTable="handleTableChange">
        <template v-slot:title="{ row: record }">
            <router-link :to="'/ecommerce/product-management/product/save/' + record.id" target="_blank" class="fw-bold text-gray-600 text-hover-primary">
                {{ record.title }}
            </router-link>
        </template>
        <template v-slot:match_state="{ row: record }">
            <span :class="matchStates[record.match_state].badge" class="badge w-100px">{{ $t('pages.ecommerce.invoiceManagement.provider.product.matchStates.' + record.match_state) }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </a>
                <el-popconfirm v-if="record.match_state != 'none'" :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_product_relation" ref="productRelationModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                        <InvoiceProviderProductRelation :providerID="providerID" ref="product" @successRequest="successRelationRequest"></InvoiceProviderProductRelation>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
import InvoiceProviderProductRelation from "@/components/ecommerce/invoice/provider/product-relation";

export default {
    name: "_id",
    components: {
        CustomTable,
        InvoiceProviderProductRelation
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.ecommerce.invoiceManagement.provider.product.cols.title"),
                    scopedSlots: {customRender: "title"}
                },
                {
                    name: this.$t("pages.ecommerce.invoiceManagement.provider.product.cols.matchState"),
                    scopedSlots: {customRender: "match_state"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            form: {
                title: '',
                show: false
            },
            provider: {},
            matchStates: {
                none: {
                    badge: 'badge-danger'
                },
                full: {
                    badge: 'badge-success'
                },
                partial: {
                    badge: 'badge-warning'
                }
            }
        }
    },
    computed: {
        table() {
            return this.$store.state.ecommerce.invoiceManagement.provider.product.productStructure.table;
        },
        providerID() {
            return this.$route.params.id;
        }
    },
    created() {
        if (!this.providerID || !(this.providerID > 0)) {
            this.$router.push({
                path: "/ecommerce/invoice-management/provider"
            });
        }
    },
    mounted() {
        if (this.providerID && this.providerID > 0) {
            this.loadProvider();
            this.$store.dispatch('ecommerce/invoiceManagement/provider/product/productStructure/get', {
                page: {},
                filterData:Object.assign( {
                    provider_id: this.providerID
                }, this.$root.getFilterWithUrl({}))
            });
        }
    },
    methods: {
        loadProvider() {
            this.axios.get(this.endpoints['ecommerce_invoice_provider'] + '/' + this.providerID).then((response) => {
                let data = response.data.data;
                this.provider = data;
            });
        },
        fetchRecord(record) {
            this.form.title = this.sprintf(this.$t("pages.ecommerce.invoiceManagement.provider.product.editPattern"), [record.title]);

            this.$refs.product.loadAllData({
                product_id: record.id
            }).then((response) => {
                if (response.status) {
                    this.showModal(this.$refs.productRelationModal);
                }
            });
        },
        deleteRecord(id) {
            this.$store.dispatch("ecommerce/invoiceManagement/provider/product/productStructure/delete", {
                id: id,
                params: {
                    provider_id: this.providerID
                }
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("ecommerce/invoiceManagement/provider/product/productStructure/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("ecommerce/invoiceManagement/provider/product/productStructure/get", {
                page: pagination,
                filterData: Object.assign({
                    provider_id: this.providerID
                }, filterData)
            });
        },
        successRelationRequest() {
            this.refreshTable();
        }
    }
}
</script>

<style scoped>

</style>